<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="content">
    <div class="contantTop flex flex_b">
      <el-breadcrumb class="xiaxia" separator="/">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="tipButton flex flex_ju_center flex_center fs-16" @click="addDislog">新增</div>
    </div>
    <div class="contant flex flex_row">
      <div class="leftContant">
        <el-tree
          id="trees"
          class="tree"
          :props="defaultProps"
          ref="asyncTree"
          :indent="0"
          :load="loadNode"
          draggable
          :allow-drop="allowDrop"
          :allow-drag="allowDrag"
          @node-drop="dragEnter"
          lazy
        >
          <span
            class="custom-tree-node"
            @click="updataTree(data)"
            slot-scope="{ node, data }"
            @mouseleave="onMouseOver(data, $event)"
            @mouseover="onMouseOut(data, $event)"
          >
            <i :class="data.folder ? '' : 'el-icon-folder-opened'"></i>
            <span>{{ data.name }}</span>
            <!-- && data.del -->
            <span v-if="data.folder && showId==data.id">
              <el-dropdown @command="handleCommand($event, data)">
                <i class="el-icon-setting"></i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="menuAdd" command="add">新增菜单</el-dropdown-item>
                  <el-dropdown-item v-if="menuUpd" command="edit">编辑菜单</el-dropdown-item>
                  <el-dropdown-item v-if="menuDel" command="del">删除菜单</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </span>
        </el-tree>
      </div>
      <div class="rightContant">
        <el-table
          border
          :data="sysTable"
          style="width: 100%;margin-bottom: 20px;"
          :header-cell-style="this.utils.getRowClass"
        >
          <el-table-column align="center" prop="sort" label="排序"></el-table-column>
          <el-table-column align="center" prop="uniqKey" label="权限标识"></el-table-column>
          <el-table-column align="center" prop="name" label="菜单名称"></el-table-column>
          <el-table-column align="center" prop="url" label="菜单地址"></el-table-column>
          <el-table-column align="center" prop="folder" label="菜单类型">
            <template slot-scope="scope">{{ scope.row.folder ? '文件夹' : '菜单' }}</template>
          </el-table-column>
          <!-- <el-table-column align="center" prop="updateDate" label="修改时间"> </el-table-column> -->
          <el-table-column align="center" prop="address" label="操作">
            <template slot-scope="scope">
              <div class="flex flex_row flex_ju_center">
                <div class="border_center_left" v-if="menuUpd" @click="updateData(scope.row)">编辑</div>
                <div class="border_center_left" v-if="menuDel" @click="delDate(scope.row.id)">删除</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="hr" v-if="!isFolder"></div>
        <div class="contantTop flex flex_end">
          <div
            v-if="!isFolder && priAdd"
            class="tipButton addQx flex flex_ju_center flex_center fs-16"
            @click="addDislogQx = true"
          >新增权限</div>
        </div>
        <el-table
          v-if="!isFolder"
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{background:'#f5f6f8'}"
          border
        >
          <el-table-column align="center" prop="name" label="权限名称"></el-table-column>
          <el-table-column align="center" prop="uniqKey" label="权限标识"></el-table-column>
          <!-- <el-table-column align="center" prop="updateDate" label="修改时间"> </el-table-column> -->
          <el-table-column align="center" prop="address" label="操作">
            <template slot-scope="scope">
              <div class="flex flex_row flex_ju_center">
                <div class="border_center_left" v-if="priUpd" @click="updateSys(scope.row, scope.$index)">编辑</div>
                <div class="color_font" v-if="priDel" @click="delDate(scope.row.id, scope.$index)">删除</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 新增权限 -->
    <el-dialog
      title="添加"
      :visible.sync="addDislogQx"
      width="30%"
      :close-on-click-modal="false"
      @close="addDislogQxs('ruleForm')"
    >
      <div class="dialong-content">
        <div class="dialong-title">名称</div>
        <el-input maxlength="15" placeholder="请输入名称" v-model="menuForm.name"></el-input>
      </div>
      <div class="dialong-content">
        <div class="dialong-title">唯一标识</div>
        <el-input maxlength="30" placeholder="请输入唯一标识" v-model="menuForm.uniqKey"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDislogQxs">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="addSys()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改权限 -->
    <el-dialog
      title="编辑"
      :visible.sync="editDislogQx"
      width="30%"
      :close-on-click-modal="false"
      @close="editDislogQxs('ruleForm')"
    >
      <div class="dialong-content">
        <div class="dialong-title">名称</div>
        <el-input maxlength="15" placeholder="请输入名称" v-model="editForm.name"></el-input>
      </div>
      <div class="dialong-content">
        <div class="dialong-title">唯一标识</div>
        <el-input maxlength="30" placeholder="请输入唯一标识" v-model="editForm.uniqKey"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDislogQxs">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="editSys()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加菜单 -->
    <el-dialog
      title="添加"
      :visible.sync="addMenuDislog"
      width="30%"
      :close-on-click-modal="false"
      @close="addhandleClose('ruleForm')"
    >
      <div class="dialong-content">
        <div class="dialong-title">菜单ICON</div>
        <el-input maxlength="40" placeholder="请输入菜单ICON" v-model="addForm.icon"></el-input>
      </div>
      <div class="dialong-content">
        <div class="dialong-title">菜单类型</div>
        <el-radio-group v-model="addForm.folder">
          <el-radio :label="1">文件夹</el-radio>
          <el-radio :label="2">菜单</el-radio>
        </el-radio-group>
      </div>
      <div class="dialong-content">
        <div class="dialong-title">菜单名称</div>
        <el-input maxlength="15" placeholder="请输入菜单名称" v-model="addForm.name"></el-input>
      </div>
      <div class="dialong-content" v-if="addForm.folder == 2">
        <div class="dialong-title">菜单地址</div>
        <el-input placeholder="请输入菜单地址" v-model="addForm.url"></el-input>
      </div>
      <div class="dialong-content">
        <div class="dialong-title">排序</div>
        <el-input placeholder="请输入排序" type="number" v-model="addForm.sort"></el-input>
      </div>
      <div class="dialong-content">
        <div class="dialong-title">权限标识</div>
        <el-input placeholder="请输入权限标识" type v-model="addForm.uniqKey"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addhandleClose">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="addMenu()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑菜单 -->
    <el-dialog
      title="编辑菜单"
      :visible.sync="editMenuDislog"
      width="30%"
      :close-on-click-modal="false"
      @close="editMenuDislog = false"
    >
      <div class="dialong-content">
        <div class="dialong-title">菜单ICON</div>
        <el-input maxlength="40" placeholder="请输入菜单ICON" v-model="form.icon"></el-input>
      </div>
      <div class="dialong-content">
        <div class="dialong-title">菜单类型</div>
        <el-radio-group v-model="form.folder">
          <el-radio :label="true">文件夹</el-radio>
          <el-radio :label="false">菜单</el-radio>
        </el-radio-group>
      </div>
      <div class="dialong-content">
        <div class="dialong-title">菜单名称</div>
        <el-input maxlength="15" placeholder="请输入菜单名称" v-model="form.name"></el-input>
      </div>
      <div class="dialong-content" v-if="!form.folder">
        <div class="dialong-title">菜单地址</div>
        <el-input placeholder="请输入菜单菜单地址" v-model="form.url"></el-input>
      </div>
      <div class="dialong-content">
        <div class="dialong-title">排序</div>
        <el-input placeholder="请输入排序" type="number" v-model="form.sort"></el-input>
      </div>
      <div class="dialong-content">
        <div class="dialong-title">权限标识</div>
        <el-input placeholder="请输入权限标识" v-model="form.uniqKey"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeMenu">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="editMenu()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      showId: -1,
      showFlag: false,
      rootNode: [],
      resolve: [],
      isDel: true,
      currentPage: 1,
      total: 0,
      isFolder: true,
      fullscreenLoading: false,
      addMenuDislog: false, //新增菜单
      editMenuDislog: false, //编辑菜单
      addDislogQx: false, //新增权限
      editDislogQx: false, //修改权限
      loading: false,
      defaultProps: {
        children: "zones",
        label: "name",
        isLeaf: "leaf"
      },
      menuForm: {
        name: "",
        parent: "",
        uniqKey: ""
      },
      editForm: {
        name: "",
        parent: "",
        uniqKey: ""
      },
      addForm: {
        parent: -1,
        name: "",
        sort: 1,
        folder: "",
        icon: "",
        type: 0,
        uniqKey: "",
        url: ""
      }, //新增菜单
      form: {}, //编辑菜单
      delId: "", //删除菜单id
      sysTable: [], //菜单详情
      queryData: {
        url: "", //路由
        name: "", //名称
        parent: -1 //父级id
      },
      tableData: [], //人员列表
      menuIndex: 0, //table下标
      isListDel: false, //是否刷新树
      seen: false,
      jurisdictionList: [],
      priAdd: false,
      priUpd: false,
      priDel: false,
      menuAdd: false,
      menuUpd: false,
      menuDel: false,
    };
  },
  mounted() {
    this.jurisdictionList = JSON.parse(localStorage.getItem("jurisdictionList"));
    this.jurisdictionList.forEach((v) => {
      if (v == "pri:add") {
        this.priAdd = true;
      }
      if (v == "pri:upd") {
        this.priUpd = true;
      }
      if (v == "pri:del") {
        this.priDel = true;
      }
      if (v == "menu:add") {
        this.menuAdd = true;
      }
      if (v == "menu:upd") {
        this.menuUpd = true;
      }
      if (v == "menu:del") {
        this.menuDel = true;
      }
    });
  },
  methods: {
    allowDrop(draggingNode, dropNode, type) {
      if (draggingNode.data.id !== dropNode.data.id) {
        if (!dropNode.parent.data) {
          let temp = [];
          dropNode.parent.childNodes.forEach(item => {
            temp.push(item.data);
          });
          dropNode.parent.data = temp;
        }
        return true;
      }
    },
    allowDrag(draggingNode) {
      return draggingNode;
    },
    dragEnter(node, drop, ev) {
      let _that = this;
      let data = {
        id: node.data.id,
        parent: drop.parent.data.id || -1
      };
      if (drop.parent.data.folder || data.parent == "-1") {
        _that.$api.updateMenu(data).then(res => {
          if (res.data.code == 200) {
            _that.isDel = true;
            _that.resetNode();
            _that.utils.success("修改成功");
          } else {
            this.utils.error(res.data.message);
          }
        });
      } else {
        this.utils.error("无法拖动至当前位置");
      }
    },
    // 鼠标移除显示
    onMouseOver(data, $event) {
      // this.showId=-1
      this.$set(data, "del", false);
    },
    // 鼠标移上显示
    onMouseOut(data, $event) {
      this.showId = data.id;
      this.$set(data, "del", true);
    },
    resetNode() {
      this.rootNode.childNodes = [];
      this.loadNode(this.rootNode, this.rootResolve);
    },
    addDislog() {
      this.addMenuDislog = true;
    },
    addDislogQxs() {
      let data = {
        name: "",
        parent: "",
        uniqKey: ""
      };
      this.menuForm = data;
      this.addDislogQx = false;
    },
    editDislogQxs() {
      let data = {
        name: "",
        parent: "",
        uniqKey: ""
      };
      this.editForm = data;
      this.editDislogQx = false;
    },
    // 编辑权限回显
    updateSys(e, index) {
      this.menuIndex = index;
      let data = {
        name: e.name,
        parent: e.parent,
        uniqKey: e.uniqKey,
        id: e.id
      };
      this.editForm = data;
      this.editDislogQx = true;
    },
    // 修改权限
    editSys() {
      let _that = this;
      let data = _that.editForm;
      if (!data.name) {
        _that.utils.error("请输入权限名称");
        return;
      }
      if (!data.uniqKey) {
        _that.utils.error("请输入权限标识");
        return;
      }
      _that.loading = true;
      this.$api.upd(data).then(res => {
        if (res.data.code == 200) {
          _that.tableData[_that.menuIndex].name = res.data.data.name;
          _that.tableData[_that.menuIndex].id = res.data.data.id;
          _that.tableData[_that.menuIndex].uniqKey = res.data.data.uniqKey;
          _that.loading = false;
          _that.editDislogQx = false;
          _that.isDel = true;
          _that.utils.success("修改成功");
        } else {
          this.utils.error(res.data.message);
          _that.loading = false;
        }
      });
    },
    // 删除菜单权限
    delUser(e) {
      let _that = this;
      let data = {
        id: e
      };
      this.$api.del(data).then(res => {
        if (res.data.code == 200) {
          _that.isDel = true;
          _that.resetNode();
          _that.utils.success(res.data.message);
        } else {
          this.utils.error(res.data.message);
          _that.fullscreenLoading = false;
        }
      });
    },
    //获取菜单详情
    updateData(row) {
      this.editMenuDislog = true;
      let data = {
        parent: row.parent,
        name: row.name,
        sort: row.sort,
        folder: row.folder,
        icon: row.icon,
        type: row.type,
        uniqKey: row.uniqKey,
        url: row.url,
        id: row.id
      };
      this.form = data;
    },
    // 关闭菜单弹框
    closeMenu() {
      let _that = this;
      _that.loading = false;
      _that.editMenuDislog = false;
    },
    // 修改菜单
    editMenu() {
      let _that = this;
      let data = _that.form;
      data.folder = data.folder ? 1 : 2;
      if (!data.icon) {
        _that.utils.error("请输入icon");
        return;
      }
      if (!data.folder) {
        _that.utils.error("请选择菜单类型");
        return;
      }
      if (!data.name) {
        _that.utils.error("请输入菜单名称");
        return;
      }
      if (data.folder == 2) {
        if (!data.url) {
          _that.utils.error("请输入菜单地址");
          return;
        }
      }

      if (!data.sort) {
        _that.utils.error("请输入排序");
        return;
      }
      if (data.folder == 1) {
        if (!data.uniqKey) {
          _that.utils.error("请输入权限标识");
          return;
        }
      }

      data.folder = data.folder == 1 ? true : false;
      _that.loading = true;
      this.$api.updateMenu(data).then(res => {
        if (res.data.code == 200) {
          _that.sysTable = [];
          let row = res.data.data;
          _that.loading = false;
          this.editMenuDislog = false;
          _that.isDel = true;
          _that.resetNode();
          _that.sysTable.push(row);
          _that.utils.success("修改成功");
        } else {
          this.utils.error(res.data.message);
          _that.fullscreenLoading = false;
          _that.loading = false;
        }
      });
    },
    // 新增权限
    addSys() {
      let _that = this;
      let data = _that.menuForm;
      if (!data.name) {
        _that.utils.error("请输入权限名称");
        return;
      }
      if (!data.uniqKey) {
        _that.utils.error("请输入权限标识");
        return;
      }
      data.parent = _that.queryData.parent;
      _that.$api.pridd(data).then(res => {
        if (res.data.code == 200) {
          _that.addDislogQx = false;
          _that.isDel = true;
          _that.tableData.push(res.data.data);
          // _that.resetNode()
          _that.utils.success("新增成功");
        } else {
          _that.utils.error(res.data.message);
          _that.fullscreenLoading = false;
        }
      });
    },

    // 获取菜单详情
    updataTree(e) {
      let _that = this;
      _that.sysTable = [];
      _that.queryData.parent = e.id; //菜单id
      _that.isFolder = e.folder; //是否是文件夹
      _that.sysTable.push(e);
      _that.tableData = [];
      if (e.folder) {
        _that.queryData.type = 0;
      } else {
        _that.queryData.type = 1;
      }
      let data = _that.queryData;
      this.$api.menuList(data).then(res => {
        if (res.data.code == 200) {
          let list = [];
          list = res.data.data;
          _that.tableData = list;
        } else {
          this.utils.error(res.data.message);
        }
      });
    },
    handleCommand(e, item) {
      let _that = this;
      if (e == "add") {
        _that.addMenuDislog = true;
        _that.addForm.id = item.id;
        _that.addForm.parent = item.id;
      } else if (e == "edit") {
        _that.updateData(item);
      } else {
        _that.isListDel = true;
        _that.delMenu(item.id, item);
      }
    },
    delDate(id) {
      this.isListDel = true;
      this.delMenu(id);
    },
    // 删除菜单
    delMenu(id) {
      let _that = this;
      let data = {
        id: id
      };
      this.$confirm("此操作将删除该菜单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error"
      }).then(() => {
        _that.$api.delMenu(data).then(res => {
          if (res.data.code == 200) {
            _that.isDel = true;
            _that.tableData.splice(_that.menuIndex, 1);
            // _that.menuIndex
            if (_that.isListDel) {
              _that.resetNode();
            }
            _that.isListDel = false;
            _that.addMenuDislog = false;
            _that.utils.success("删除成功");
          } else {
            _that.utils.error(res.data.message);
            _that.fullscreenLoading = false;
          }
        });
      });
    },
    addhandleClose() {
      let _that = this;
      _that.addMenuDislog = false;
      _that.loading = false;
      let data = {
        parent: -1,
        name: "",
        sort: 1,
        folder: "",
        icon: "",
        type: 0,
        uniqKey: "",
        url: ""
      };
      _that.addForm = data;
    },
    // 删除菜单
    delData(id, index) {
      this.delMenu(id, index);
      this.menuIndex = index;
    },
    // 新增菜单
    addMenu() {
      let _that = this;
      let data = _that.addForm;
      if (!data.icon) {
        _that.utils.error("请输入icon");
        return;
      }
      if (!data.folder) {
        _that.utils.error("请选择菜单类型");
        return;
      }
      if (!data.name) {
        _that.utils.error("请输入菜单名称");
        return;
      }
      if (data.folder == 2) {
        if (!data.url) {
          _that.utils.error("请输入菜单地址");
          return;
        }
      }

      if (!data.sort) {
        _that.utils.error("请输入排序");
        return;
      }
      if (data.folder == 1) {
        if (!data.uniqKey) {
          _that.utils.error("请输入权限标识");
          return;
        }
      }
      data.folder = data.folder == 1 ? true : false;
      _that.loading = true;
      _that.$api.addMenu(data).then(res => {
        if (res.data.code == 200) {
          _that.isDel = true;
          _that.resetNode();
          _that.loading = false;
          _that.addMenuDislog = false;
          _that.addhandleClose();
          _that.utils.success("新增成功");
        } else {
          _that.utils.error(res.data.message);
          _that.loading = false;
        }
      });
    },
    // 获取父级部门 tree
    queryDeptList(resolve) {
      let _that = this;
      _that.tableData = [];
      if (_that.isDel) {
        _that.queryData.parent = -1;
      }
      _that.queryData.type = "";
      let data = _that.queryData;
      this.$api.menuList(data).then(res => {
        if (res.data.code == 200) {
          let data = res.data.data;
          data.map(item => {
            item.leaf = !item.folder;
          });
          // _that.tableData = data
          _that.isDel = false;
          resolve(data);
        } else {
          this.utils.error(res.data.message);
        }
      });
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        this.rootNode = node;
        this.rootResolve = resolve;
        this.queryDeptList(resolve); //获取父节点数据
      }
      if (node.level >= 1) {
        this.getChildByList(node.data.id, resolve); //获取子节点数据
        return resolve([]);
      }
    },

    // 获取子级部门 tree
    getChildByList(data, resolve) {
      let _that = this;
      if (_that.isDel) {
        _that.queryData.parent = -1;
      }
      // else {
      //   _that.queryData.parent = data
      // }
      _that.queryData.parent = data;
      _that.queryData.type = 0;
      _that.tableData = [];
      let queryData = _that.queryData;
      this.$api.menuList(queryData).then(res => {
        if (res.data.code == 200) {
          let data = res.data.data;

          data.map(item => {
            item.leaf = !item.folder;
          });
          // _that.tableData = data
          _that.isDel = false;
          resolve(data);
        } else {
          this.utils.error(res.data.message);
        }
      });
    }
  }
};
</script>
<style lang="less">
.custom-tree-node {
  width: 100%;
  font-size: 16px;
}
.tree {
  .el-tree-node {
    position: relative;
    padding-left: 16px; // 缩进量
  }
  .el-tree-node__children {
    padding-left: 16px; // 缩进量
  }

  // 竖线
  .el-tree-node::before {
    content: "";
    height: 100%;
    width: 1px;
    position: absolute;
    left: -3px;
    top: -26px;
    border-width: 1px;
    border-left: 1px dashed #d6d7d8;
  }
  // 当前层最后一个节点的竖线高度固定
  .el-tree-node:last-child::before {
    height: 38px; // 可以自己调节到合适数值
  }

  // 横线
  .el-tree-node::after {
    content: "";
    width: 24px;
    height: 20px;
    position: absolute;
    left: -3px;
    top: 12px;
    border-width: 1px;
    border-top: 1px dashed #d6d7d8;
  }

  & > .el-tree-node::after {
    border-top: none;
  }
  & > .el-tree-node::before {
    border-left: none;
  }
  .el-tree-node__expand-icon {
    font-size: 16px;
    &.is-leaf {
      color: transparent;
    }
  }
}
</style>
<style lang="less" scoped>
.contant {
  min-height: 500px;
  .leftContant {
    width: 20%;
  }
  .rightContant {
    width: 80%;
  }
  .color_font {
    color: #409eff;
    font-size: 14px;
    cursor: pointer;
  }
}
.dialong-content {
  margin-bottom: 20px;
  .dialong-title {
    margin-bottom: 10px;
  }
}
.el-button--primary {
  background-color: #409eff !important;
  border-color: #409eff !important;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  border: 1px dotted;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.hr {
  width: 100%;
  height: 1px;
  background: #eee;
  margin-top: 30px;
}
.addQx {
  margin-bottom: 20px;
}
</style>
<style lang="less">
.tree-line {
  .el-tree-node {
    position: relative;
    padding-left: 16px; // 缩进量
  }
  .el-tree-node__children {
    padding-left: 16px; // 缩进量
  }

  // 竖线
  .el-tree-node::before {
    content: "";
    height: 100%;
    width: 1px;
    position: absolute;
    left: -3px;
    top: -26px;
    border-width: 1px;
    border-left: 1px dashed #52627c;
  }
  // 当前层最后一个节点的竖线高度固定
  .el-tree-node:last-child::before {
    height: 38px; // 可以自己调节到合适数值
  }

  // 横线
  .el-tree-node::after {
    content: "";
    width: 24px;
    height: 20px;
    position: absolute;
    left: -3px;
    top: 12px;
    border-width: 1px;
    border-top: 1px dashed #52627c;
  }

  // 去掉最顶层的虚线，放最下面样式才不会被上面的覆盖了
  & > .el-tree-node::after {
    border-top: none;
  }
  & > .el-tree-node::before {
    border-left: none;
  }

  // 展开关闭的icon
  .el-tree-node__expand-icon {
    font-size: 16px;
    // 叶子节点（无子节点）
    &.is-leaf {
      color: transparent;
      // display: none; // 也可以去掉
    }
  }
}
.fs-16 {
  font-size: 16px;
}
</style>
