import { render, staticRenderFns } from "./menu.vue?vue&type=template&id=47dd8086&scoped=true&"
import script from "./menu.vue?vue&type=script&lang=js&"
export * from "./menu.vue?vue&type=script&lang=js&"
import style0 from "./menu.vue?vue&type=style&index=0&lang=less&"
import style1 from "./menu.vue?vue&type=style&index=1&id=47dd8086&lang=less&scoped=true&"
import style2 from "./menu.vue?vue&type=style&index=2&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47dd8086",
  null
  
)

export default component.exports